import { Injectable } from '@angular/core';
import { Routes } from '@angular/router';
import { AccountService } from 'src/app/providers/account.service';
export interface BadgeItem {
    type: string;
    value: string;
}
export interface Saperator {
    name: string;
    type?: string;
}
export interface SubChildren {
    state: string;
    name: string;
    type?: string;
}
export interface ChildrenItems {
    state: string;
    name: string;
    type?: string;
    child?: SubChildren[];
}

export interface Menu {
    state: string | string[];
    name: string;
    type: string;
    icon: string;
    badge?: BadgeItem[];
    saperator?: Saperator[];
    children?: ChildrenItems[];
}

export const BAO_CAO_TONG_HOP = {
    state: 'synthetic',
    name: 'Reporting',
    type: 'sub',
    icon: 'library_books',
    children: [
        { state: 'bao-cao-tn', name: 'bao_cao_thuong_nien', type: 'link' },
        { state: 'bao-cao-ban-dt', name: 'bao_cao_ban_dt', type: 'link' },
        { state: 'ql-bao-cao-dinh-ky', name: 'quan_ly_bao_cao_dinh_ky', type: 'link' },
    ],
};

export const DASHBOARD_MENUITEMS = {
    state: 'dashboards',
    name: 'Dashboards',
    type: 'link',
    icon: 'av_timer',
};

export const UNIVERSITY_MENUITEMS = {
    state: 'university',
    name: 'University Management',
    type: 'sub',
    icon: 'book',
    children: [
        { state: 'admissionlearner', name: 'Admission Learner', type: 'link' },
        { state: 'learner', name: 'Students', type: 'link' },
        { state: 'graduatelearner', name: 'Graduate Learner', type: 'link' },
    ],
};

export const POSTGRADUATE_MENUITEMS = {
    state: 'postgraduate',
    name: 'Postgraduate Management',
    type: 'sub',
    icon: 'school',
    children: [
        { state: 'admissionstudent', name: 'Admission Student', type: 'link' },
        { state: 'student', name: 'Graduated Students', type: 'link' },
        { state: 'graduatestudent', name: 'Graduate Student', type: 'link' },
    ],
};

export const QUAN_LY_NGANH = {
    state: 'open-major-management',
    name: 'quan_ly_mo_nganh',
    type: 'sub',
    icon: 'manage_accounts',
    children: [
        { state: 'list-major', name: 'danh_sach_nganh_hoc', type: 'link' },
        { state: 'list-register', name: 'danh_sach_dang_ky', type: 'link' },
    ],
};

export const CURRICULUM_MENUITEMS = {
    state: 'curriculum',
    name: 'Curriculum Management',
    type: 'sub',
    icon: 'menu_book',
    children: [
        { state: 'curriculumlist', name: 'Curriculum List', type: 'link' },
        { state: 'alternativecurriculumlist', name: 'Alternative Curriculum List', type: 'link' },
    ],
};

export const FILES_MENUITEMS = {
    state: 'files',
    name: 'Files',
    type: 'sub',
    icon: 'archive',
    children: [{ state: 'filesdownloaded', name: 'Files Downloaded', type: 'link' }],
};

// export const UTILITIES_MENUITEMS = {
//     state: 'utitlities',
//     name: 'Utitlities',
//     type: 'sub',
//     icon: 'extension',
//     children: [
//         { state: 'utilitiesaddressward', name: 'Ward Address Normalization', type: 'link' },
//         { state: 'utilitiesaddressdistrict', name: 'District Address Normalization', type: 'link' },
//         { state: 'utilitiesaddressprovince', name: 'Province Address Normalization', type: 'link' },
//     ],
// };

export const ACCOUNT_MENUITEMS = {
    state: 'account',
    name: 'Account',
    type: 'sub',
    icon: 'manage_accounts',
    children: [{ state: 'editaccount', name: 'Edit Account', type: 'link' }],
};

export const KHAO_SAT_MENUITEMS = {
    state: 'khao-sat',
    name: 'khao_sat',
    type: 'sub',
    icon: 'format_align_left',
    children: [
        { state: 'danh-sach', name: 'danh_sach_khao_sat', type: 'link' },
        { state: 'quan-ly', name: 'quan_ly_khao_sat', type: 'link' },
        { state: 'thong-ke', name: 'thong_ke_khao_sat', type: 'link' },
    ],
};

export const MENU_ITEMS_CTDT = {
    state: ['ctdt', 'general'],
    name: 'chuong_trinh_dao_tao',
    type: 'link',
    icon: 'book',
};

export const MENUITEMS = [
    DASHBOARD_MENUITEMS,
    UNIVERSITY_MENUITEMS,
    POSTGRADUATE_MENUITEMS,
    QUAN_LY_NGANH,
    CURRICULUM_MENUITEMS,
    MENU_ITEMS_CTDT,
    BAO_CAO_TONG_HOP,
    // KHAO_SAT_MENUITEMS,
    FILES_MENUITEMS,
    // UTILITIES_MENUITEMS,
    ACCOUNT_MENUITEMS,
];

@Injectable({ providedIn: 'root' })
export class MenuItems {
    constructor(private accountService: AccountService) {}

    getMenuitem(): Menu[] {
        return MENUITEMS;
    }
}

export function updateMenuItemsRole() {}
