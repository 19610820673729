import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { AccountService } from '../account.service';
import { HostnameService } from '../hostname.service';
import { bandt_ctdt_check_for_role } from './roles.ban-dt.ctdt.utils';
import { bcth_tn_check_for_role } from './roles.bcth.tn.utils';
import { bcth_dt_check_for_role } from './roles.bcth.dt.utils';

@Injectable({ providedIn: 'root' })
export class RolesPermissionService {
    private _data: Map<string, any> = new Map();
    private _dataSub: Subject<Map<string, any>> = new Subject();

    get data() {
        return this._data;
    }
    get dataSub() {
        return this._dataSub;
    }

    constructor(
        private accountService: AccountService,
        private hostnameService: HostnameService,
        protected readonly keyCloakService: KeycloakService
    ) {
        this._data.clear();
        this._data.set('roles', []);
        this._data.set('bcth_bc_tn', {});
        this._data.set('bandt_ctdt', {});
        this._data.set('bcth_bc_dt', {});
    }

    changeForRole(newRoles: string[]) {
        let currentValues: any = this._data.get('roles');
        if (currentValues.length == newRoles.length && newRoles.every((role: string) => currentValues.includes(role)))
            return;

        this._data.set('roles', newRoles);
        this._data.set('bcth_bc_tn', bcth_tn_check_for_role(newRoles));
        this._data.set('bandt_ctdt', bandt_ctdt_check_for_role(newRoles));
        this._data.set('bcth_bc_dt', bcth_dt_check_for_role(newRoles));
        this._dataSub.next(this._data);
    }
}
