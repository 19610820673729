import { Component, OnDestroy, OnInit } from '@angular/core';
import { GeneralDataService } from './providers/general-data.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { LanguageService } from './providers/language.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnDestroy, OnInit {
    // Translate
    private languageSub!: Subscription;

    constructor(
        private languageService: LanguageService,
        private translate: TranslateService,
        private cookieService: CookieService,
        private generalDataService: GeneralDataService
    ) {
        this.translate.use(this.languageService.language);
        this.languageSub = this.languageService.languageSub.subscribe((newLanguage: string) => {
            this.translate.use(this.languageService.language);
            this.cookieService.set('language', this.languageService.language);
        });
        translate.setDefaultLang('vn');
    }

    ngOnInit() {
        this.generalDataService.fetch();
    }

    async ngAfterViewInit() {
        let savedLanguage: string = this.cookieService.get('language');
        if (!savedLanguage) savedLanguage = 'vn';
        this.languageService.language = savedLanguage;
    }

    ngOnDestroy(): void {
        if (this.languageSub) this.languageSub.unsubscribe();
    }

    writeTranslateCookie() {}
}
