import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { AccountService } from './account.service';
import { HostnameService } from './hostname.service';

@Injectable({ providedIn: 'root' })
export class UtilitieAddressService {
    constructor(
        private httpClient: HttpClient,
        private accountService: AccountService,
        private hostnameService: HostnameService
    ) {}

    downloadTemplate() {
        let url =
            this.hostnameService.VnuisAPI_Readv1 +
            '/utilities/download_example_standard-address.xlsx?apikey=' +
            this.accountService.apikey;
        var a = document.createElement('a');
        a.href = url;
        a.setAttribute('download', 'template.xlsx');
        a.click();
    }

    downloadTemplateQH() {
        let url =
            this.hostnameService.VnuisAPI_Readv1 +
            '/utilities/download_example_standard-tt-qh.xlsx?apikey=' +
            this.accountService.apikey;
        var a = document.createElement('a');
        a.href = url;
        a.setAttribute('download', 'template.xlsx');
        a.click();
    }

    downloadTemplateTT() {
        let url =
            this.hostnameService.VnuisAPI_Readv1 +
            '/utilities/download_example_standard-tt.xlsx?apikey=' +
            this.accountService.apikey;
        var a = document.createElement('a');
        a.href = url;
        a.setAttribute('download', 'template.xlsx');
        a.click();
    }

    async uploadDataForMultiple(data: any) {
        let final_metadata: any = this.accountService.getMetadata();
        return await new Promise<any>((resolve, reject) => {
            this.httpClient
                .post<any>(
                    this.hostnameService.VnuisAPI_Readv1 +
                        '/utilities/process-multiple-standard-address?apikey=' +
                        this.accountService.apikey,
                    {
                        data: data,
                        metadata: final_metadata,
                    }
                )
                .subscribe((data) => {
                    resolve(data);
                });
        });
    }

    async uploadDataForMultipleQH(data: any) {
        let final_metadata: any = this.accountService.getMetadata();
        return await new Promise<any>((resolve, reject) => {
            this.httpClient
                .post<any>(
                    this.hostnameService.VnuisAPI_Readv1 +
                        '/utilities/process-multiple-standard-tt-qh?apikey=' +
                        this.accountService.apikey,
                    {
                        data: data,
                        metadata: final_metadata,
                    }
                )
                .subscribe((data) => {
                    resolve(data);
                });
        });
    }

    async uploadDataForMultipleTT(data: any) {
        let final_metadata: any = this.accountService.getMetadata();
        return await new Promise<any>((resolve, reject) => {
            this.httpClient
                .post<any>(
                    this.hostnameService.VnuisAPI_Readv1 +
                        '/utilities/process-multiple-standard-tt?apikey=' +
                        this.accountService.apikey,
                    {
                        data: data,
                        metadata: final_metadata,
                    }
                )
                .subscribe((data) => {
                    resolve(data);
                });
        });
    }

    getStandardFile() {
        let tsms = new Date().getTime();
        let url =
            this.hostnameService.VnuisAPI_Readv1 +
            '/utilities/get-standard-address?apikey=' +
            this.accountService.apikey +
            '&ma_don_vi=' +
            this.accountService.getMetadata().ma_don_vi +
            '&tsms=' +
            tsms;
        window.location.href = url;
    }
}
