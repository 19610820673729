import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LanguageService {
    // Thông tin cần lưu trữ
    private _language: string = 'vn';

    // Để tạo và bắt sự kiện khi có cập nhật dữ liệu
    private subjectPostUpdated: Subject<string> = new Subject<string>();

    // Thêm dữ liệu vào
    getLanguage() {
        return this._language;
    }

    get language() {
        return this._language;
    }
    set language(value) {
        this._language = value;
        this.subjectPostUpdated.next(value);
    }
    get languageSub() {
        return this.subjectPostUpdated;
    }

    // Để các lớp khác lấy service ra và bắt sự kiện
    getLanguageUpdateListener() {
        return this.subjectPostUpdated.asObservable();
    }

    // Để các lớp khác gọi và thay đổi dữ liệu
    setLanguage(newLanguage: string) {
        // Thay đổi dữ liệu
        this._language = newLanguage;
        // Thông báo có thay đổi trong dữ liệu
        this.subjectPostUpdated.next(this.getLanguage());
    }
}
