import { Injectable } from '@angular/core';

export const VNUIS_API = 'https://api.vnuhcm.edu.vn';

@Injectable({ providedIn: 'root' })
export class HostnameService {
    get VnuisAPI() {
        return VNUIS_API;
    }

    get VnuisAPI_Readv1() {
        return 'https://api.vnuhcm.edu.vn/api/v1/read';
        // return 'http://localhost:8102';
    }

    get VnuisAPI_Stagingv1() {
        return 'https://api.vnuhcm.edu.vn/api/v1/staging';
        // return 'http://localhost:8100';
    }
}
