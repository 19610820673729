import { ChangeDetectorRef, Component, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { MediaMatcher } from '@angular/cdk/layout';
import { MenuItems } from '../../../shared/menu-items/menu-items';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-vertical-sidebar',
    templateUrl: './vertical-sidebar.component.html',
    styleUrls: [],
})
export class VerticalAppSidebarComponent implements OnDestroy {
    public config: PerfectScrollbarConfigInterface = {};
    mobileQuery: MediaQueryList;

    @Input() showClass: boolean = false;
    @Output() notify: EventEmitter<boolean> = new EventEmitter<boolean>();

    private _mobileQueryListener: () => void;
    status = true;

    itemSelect: number[] = [];
    parentIndex = 0;
    childIndex = 0;

    setClickedRow(i: number, j: number): void {
        this.parentIndex = i;
        this.childIndex = j;
    }
    subclickEvent(): void {
        this.status = true;
    }
    scrollToTop(): void {
        document.querySelector('.page-wrapper')?.scroll({
            top: 0,
            left: 0,
        });
    }

    constructor(
        changeDetectorRef: ChangeDetectorRef,
        media: MediaMatcher,
        public menuItems: MenuItems,
        private title: Title,
        private translate: TranslateService
    ) {
        this.mobileQuery = media.matchMedia('(min-width: 768px)');
        this._mobileQueryListener = () => changeDetectorRef.detectChanges();
        // tslint:disable-next-line: deprecation
        this.mobileQuery.addListener(this._mobileQueryListener);
    }

    ngOnDestroy(): void {
        // tslint:disable-next-line: deprecation
        this.mobileQuery.removeListener(this._mobileQueryListener);
    }

    handleNotify(data: string) {
        this.title.setTitle(this.translate.instant(data));
        if (window.innerWidth < 1024) {
            this.notify.emit(!this.showClass);
        }
    }

    createRouterLink(state: any, childitem_state: any = undefined, child_state: any = undefined) {
        let result: string = '/';
        if (state)
            if (Array.isArray(state)) result += state.join('/');
            else result += state;
        if (childitem_state)
            if (Array.isArray(childitem_state)) result += '/' + childitem_state.join('/');
            else result += '/' + childitem_state;
        if (child_state)
            if (Array.isArray(child_state)) result += '/' + child_state.join('/');
            else result += '/' + child_state;

        return result;
    }
}
