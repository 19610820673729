import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { AccountService } from './account.service';
import { HostnameService } from './hostname.service';

export interface Student {
    ma_truong: string;
    ma_hv: string;
    email: string;
    phone: string;
    ho_ten_dem: string;
    ten: string;
    ngay_sinh: string;
    gioi_tinh: string;
    quoc_tich: string;
    dan_toc: string;
    ton_giao: string;
    xuat_than: string;
    doi_tuong: string;
    khu_vuc: string;
    vung_mien: string;
    chinh_sach: string;
    dia_chi_lien_he: string;
    so_the_bhyt: string;
    don_vi: string;
    khoa: string;
    lop_sh: string;
    he_dao_tao: string;
    khoa_hoc: string;
    nganh_hoc: string;
    chuyen_nganh: string;
    tinh_trang: string;
    ngay_vao_doan: string;
    ngay_vao_dang: string;
    ho_ten_cha: string;
    ho_ten_me: string;
    ghi_chu: string;

    noi_sinh: any;
    cmnd_cccd: any;
    que_quan: any;
    dia_chi_thuong_tru: any;
    ky_tuc_xa: any;

    diem_hoc_tap: any[];
    diem_ren_luyen: any[];
    khen_thuong: any[];
    ky_luat: any[];
    hoc_bong: any[];
}

@Injectable({ providedIn: 'root' })
export class StudentService {
    constructor(
        private httpClient: HttpClient,
        private accountService: AccountService,
        private hostnameService: HostnameService
    ) {}

    // async fetchStudent() {
    //     return await new Promise<Student[]>((resolve, reject) => {
    //         this.httpClient
    //             .post<Student[]>(this.hostnameService.VnuisAPI_Readv1 + '/student/get-all', {
    //                 apikey: this.accountService.apikey,
    //             })
    //             .subscribe((data) => {
    //                 data.sort((e1: any, e2: any) => {
    //                     if (e1.data.ma_hv < e2.data.ma_hv) return -1;
    //                     if (e1.data.ma_hv > e2.data.ma_hv) return 1;
    //                     return 0;
    //                 });
    //                 resolve(data);
    //             });
    //     });
    // }

    async fetchStudentByMetadata(ma_don_vi: string, limit_offset: any = undefined) {
        return await new Promise<Student[]>((resolve, reject) => {
            let final_metadata: any = this.accountService.getMetadata();

            this.httpClient
                .post<Student[]>(this.hostnameService.VnuisAPI_Readv1 + '/student/get-all-by-metadata', {
                    apikey: this.accountService.apikey,
                    metadata: final_metadata,
                    ma_don_vi: ma_don_vi,
                    limit_offset: limit_offset
                })
                .subscribe((data) => {
                    data.sort((e1: any, e2: any) => {
                        if (e1.data.ma_hv < e2.data.ma_hv) return -1;
                        if (e1.data.ma_hv > e2.data.ma_hv) return 1;
                        return 0;
                    });
                    resolve(data);
                });
        });
    }

    async fetchStudentPartByMetadata(ma_don_vi: string, limit_offset: any = undefined) {
        return await new Promise<Student[]>((resolve, reject) => {
            let final_metadata: any = this.accountService.getMetadata();

            this.httpClient
                .post<Student[]>(this.hostnameService.VnuisAPI_Readv1 + '/student/get-part-by-metadata', {
                    apikey: this.accountService.apikey,
                    metadata: final_metadata,
                    ma_don_vi: ma_don_vi,
                    limit_offset: limit_offset
                })
                .subscribe((data) => {
                    data.sort((e1: any, e2: any) => {
                        if (e1.data.ma_hv < e2.data.ma_hv) return -1;
                        if (e1.data.ma_hv > e2.data.ma_hv) return 1;
                        return 0;
                    });
                    resolve(data);
                });
        });
    }

    async deleteById(id: string) {
        let final_metadata: any = this.accountService.getMetadata();

        return await new Promise<any>((resolve, reject) => {
            this.httpClient
                .post<any>(this.hostnameService.VnuisAPI_Stagingv1 + '/student/delete', {
                    id: id,
                    apikey: this.accountService.apikey,
                    metadata: final_metadata,
                })
                .subscribe((data) => {
                    resolve(data);
                });
        });
    }

    extractStudentList(ma_don_vi: string, chuan_hoa: boolean = false) {
        let url =
            this.hostnameService.VnuisAPI_Readv1 + '/student/extract-list.xlsx?apikey=' +
            this.accountService.apikey;

        if (chuan_hoa) url += '&chuan_hoa=' + chuan_hoa;
        
        let final_metadata: any = this.accountService.getMetadata();
        url += '&metadata=' + JSON.stringify(final_metadata);
        url += '&ma_don_vi=' + (ma_don_vi);

        // var a = document.createElement('a');
        // a.href = url;
        // a.setAttribute('download', 'output.xlsx');
        // a.click();

        this.httpClient.get(url).subscribe((data) => {});
    }

    downloadTemplate() {
        let url =
            this.hostnameService.VnuisAPI_Readv1 + '/student/download-template.xlsx?apikey=' +
            this.accountService.apikey;
        var a = document.createElement('a');
        a.href = url;
        a.setAttribute('download', 'template.xlsx');
        a.click();
    }

    async addMultiple(students: Student[], metadata: any) {
        let final_metadata: any = this.accountService.getMetadata();
        final_metadata = { ...metadata, ...final_metadata };
        return await new Promise<any>((resolve, reject) => {
            this.httpClient
                .post<any>(
                    this.hostnameService.VnuisAPI_Stagingv1 + '/student/add-multiple' +
                        '?apikey=' +
                        this.accountService.apikey,
                    {
                        // apikey: this.accountService.apikey,
                        metadata: final_metadata,
                        data: students,
                    }
                )
                .subscribe((data) => {
                    resolve(data);
                });
        });
    }

    async count(ma_don_vi: string = '') {
        return await new Promise<any>((resolve, reject) => {
            let final_metadata: any = this.accountService.getMetadata();
            if (!ma_don_vi) ma_don_vi = '';
            this.httpClient
                .post<any>(
                    this.hostnameService.VnuisAPI_Readv1 + '/student/count',
                    {
                        metadata: final_metadata,
                        ma_don_vi: ma_don_vi,
                    },
                    {
                        headers: {
                            apikey: this.accountService.apikey,
                        },
                    }
                )
                .subscribe((data) => {
                    resolve(data);
                });
        });
    }

    async getById(student_id: string) {
        let metadata: any = this.accountService.getMetadata();
        return this.httpClient
            .post<any>(
                this.hostnameService.VnuisAPI_Readv1 + '/student/get-by-id',
                {
                    metadata: metadata,
                    id: student_id,
                },
                {
                    headers: {
                        apikey: this.accountService.apikey,
                    },
                }
            )
            .toPromise();
    }

    async addMultipleAttr(ma_don_vi: string, data: any[]) {
        let metadata: any = this.accountService.getMetadata();
        return this.httpClient
            .post<any>(
                this.hostnameService.VnuisAPI_Stagingv1 + '/student/add-multiple-attr',
                {
                    metadata: metadata,
                    data: data,
                    ma_don_vi: ma_don_vi,
                },
                {
                    headers: {
                        apikey: this.accountService.apikey,
                    },
                }
            )
            .toPromise();
    }

    async editAttrById(id: string, ma_don_vi: string, data: any) {
        let metadata: any = this.accountService.getMetadata();
        return this.httpClient
            .post<any>(
                this.hostnameService.VnuisAPI_Stagingv1 + '/student/edit-attr-by-id',
                {
                    metadata: metadata,
                    data: data,
                    ma_don_vi: ma_don_vi,
                    id: id,
                },
                {
                    headers: {
                        apikey: this.accountService.apikey,
                    },
                }
            )
            .toPromise();
    }

    async extractLyLichById(id: string, chuan_hoa: boolean = false, filetype: string='docx') {
        let metadata: any = this.accountService.getMetadata();
        return this.httpClient
            .post<any>(
                this.hostnameService.VnuisAPI_Readv1 + '/student/extract-ly-lich',
                {
                    metadata: metadata,
                    id: id,
                    chuan_hoa: chuan_hoa,
                    type: filetype
                },
                {
                    headers: {
                        apikey: this.accountService.apikey,
                    },
                }
            )
            .toPromise();
    }
}
