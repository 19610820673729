import { Routes } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';
import { AppBlankComponent } from './layouts/blank/blank.component';
import { AuthGuard } from './helper/auth.guard';

export const AppRoutes: Routes = [
    {
        path: '',
        component: FullComponent,
        canActivate: [AuthGuard],
        data: { someRoles: ['admin', 'vnuhcm.dt.chuyenvien', 'vnuhcm.dt.lanhdaoban'] },
        children: [
            {
                path: '',
                redirectTo: '/dashboards',
                pathMatch: 'full',
            },
            {
                path: 'dashboard',
                redirectTo: '/dashboards',
                pathMatch: 'full',
            },
            {
                path: 'dashboards',
                loadChildren: () => import('./dashboards/dashboards.module').then((m) => m.DashboardsModule),
            },

            // Account
            {
                path: 'account',
                loadChildren: () => import('./account/account.module').then((m) => m.AccountModule),
            },
            // // Utitlitis
            // {
            //     path: "utitlities",
            //     loadChildren: () => import("./utilities/utilities.module").then((m) => m.UtilitiesModule),
            //     // canActivate: [AuthGuard],
            // },
            // Files
            {
                path: "files",
                loadChildren: () => import("./files/files.module").then((m) => m.FilesModule),
                // canActivate: [AuthGuard],
            },

            // Ban ĐT
            {
                path: "university",
                loadChildren: () => import("./university/university.module").then((m) => m.UniversityModule),
            },
            {
                path: "postgraduate",
                loadChildren: () => import("./postgraduate/postgraduate.module").then((m) => m.PostgraduateModule),
            },
            {
                path: "curriculum",
                loadChildren: () => import("./management-curriculum/curriculum.module").then((m) => m.CurriculumModule),
            },
            {
                path: "open-major-management",
                loadChildren: () => import("./open-major-management/module").then((m) => m.FormModule),
            },

            // Synthetic
            {
                path: 'synthetic',
                loadChildren: () => import('./synthetic/synthetic.module').then((m) => m.SyntheticModule),
            },

            // {
            //     path: 'khao-sat',
            //     loadChildren: () => import('./khao_sat/khao_sat.module').then((m) => m.KhaoSatModule),
            // },

            {
                path: 'ctdt',
                loadChildren: () => import('./chuong-trinh-dt/ctdt.module').then((m) => m.CtdtModule),
            },
        ],
    },
    {
        path: '',
        component: AppBlankComponent,
        children: [
            {
                path: 'authentication',
                loadChildren: () =>
                    import('./authentication/authentication.module').then((m) => m.AuthenticationModule),
            },
        ],
    },
    {
        path: '**',
        redirectTo: 'authentication/404',
    },
];
